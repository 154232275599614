<auth-page>
    <form (ngSubmit)="sendPasswordResetLink()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
        <div class="page-header" trans>Enter your username below and we will send you a link to reset or create your password.</div>

        <div class="page-content">
            <div class="input-container">
                <label for="email" trans>Username</label>
                <input type="text" formControlName="email" id="email" required>
                <p class="error big-error" *ngIf="errors.email">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.email}}</span>
                </p>
            </div>

            <button mat-raised-button class="primary-button" color="accent" type="submit" [disabled]="loading$ | async" trans>Continue</button>
        </div>
    </form>
</auth-page>
